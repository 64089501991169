<template>
    <div class="pa-2 m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row style="text-align:center;direction:rtl" class="m-2">
            <v-col cols="12">
                <b-table-simple style="margin-left:-8px;margin-right:auto;">
                    <thead>
                        <tr>
                            <th>رقم الموظف</th>
                            <th>اسم الموظف</th>
                            <th>الراتب الاساسي</th>
                            <th>بدل السكن</th>
                            <th>بدل النقل</th>
                            <th>بدل الاطعام</th>
                            <th>بدلات اخرى</th>
                            <th>اجمالي الراتب</th>
                            <th>النسبة</th>
                            <th>الخصومات</th>
                            <th>الاجمالي</th>
                            <th>السلف</th>
                            <th>الاجراء</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in employees" :key="index">
                            <td class="text-center">{{item.mobile}}</td>
                            <td class="text-center">{{item.employee_name}}</td>
                            <td class="text-center">{{item.salary}}</td>
                            <td class="text-center">{{item.home_allown}}</td>
                            <td class="text-center">{{item.tarns_allown}}</td>
                            <td class="text-center">{{item.food_allown}}</td>
                            <td class="text-center">{{item.others_allown}}</td>
                            <td class="text-center">{{item.stotal}}</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                            <td class="text-center">
                                <b-button class="btn-sm btn-danger" @click="$router.push({path: '/emp/view/'+item.id})">استعراض</b-button>
                            </td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default{
    components: { breadCrumbs, TabsComp },
    name: "Employees",
    data() {
        return {
            employees: []
        }
    },
    methods: {
        getEmployees()
        {
            const post = new FormData();
            post.append("type" , "getAllEmp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // console.log("asdfas",response.data);
                this.employees = response.data.results.data;
            })
        }
    },
    created() {
        this.getEmployees()
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab1'
                },
                {
                    index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab2'
                },
               
                {
                    index:3,name:this.lang.employees,href:'/finance/salaries',class: 'mytab'
                },
                {
                    index:4,name:this.lang.recurring_expensess,href:'/finance/expenses',class: 'mytab5'
                }
            ]
        },
        items: function() {
            return {
                    text: this.lang.employees,
                    disabled: true,
                    to: '/finance/salaries',
                }
        }
    },
}
</script>
